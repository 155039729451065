<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />

        <div style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:15px !important;`">
                <v-col
                cols="12"
                md="10"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#fefefe;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>

                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group style="">
                            <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getReport()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <b-input-group class="mt-3" style="display:none"> 
                    <b-form-input placeholder="Start Date" style="border:1px solid #000;border-radius: 0;" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border:1px solid #000">
                        <b-button variant="light" style="background: gold !important;font-size:14px;color:#000;font-size:14px;" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right" style="display:none"> 
                    <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col> 
            </v-row>
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-10px !important;`">
                <v-col cols="12" md="12" sm="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="13" class="text-center blackBack" style="">{{lang.income_expense_report}}</th>
                            </tr>
                            <tr>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" rowspan="2">{{lang.date}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" rowspan="2">{{lang.income}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" colspan="3" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.employees}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" rowspan="2">{{lang.credit_note}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" colspan="3" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.purchase_and_expenses}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" colspan="2" v-else>{{lang.purchase_and_expenses}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" colspan="2">{{lang.payment_type}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" rowspan="2">{{lang.balance}}</th>
                                <th class="text-center backBlack" style="border-top:1px solid #000 !important;" rowspan="2">{{lang.details}}</th>
                            </tr>
                            <tr>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.salaries}}</th>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.emp_custody}}</th>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.paid_in_advanced}}</th>
                                <th class="text-center backBlack">{{lang.purchase}}</th>
                                <th class="text-center backBlack">{{lang.expnese}}</th>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.vendor_payments}}</th>
                                <th class="text-center backBlack">{{lang.cash}}</th>
                                <th class="text-center backBlack">{{lang.other}}</th>
                            </tr>
                            <!-- <tr>
                                <th class="text-left" colspan="11" v-if="$store.state.licenseType.activemenu != 'fatora'">{{lang.old_balance}}</th>
                                <th class="text-left" colspan="7" v-else>{{lang.old_balance}}</th>
                                <th class="text-center">{{lastBalance}}</th>
                                <th class="text-center"></th>
                            </tr> -->
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in report" :key="index">
                                <td class="text-center" style="direction:ltr;">{{item.date}}</td>
                                <td class="text-center">{{$convertToComa(item.income,comaMoney)}}</td>
                                <td class="text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{$convertToComa(item.salaries,comaMoney)}}</td>
                                <td class="text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{$convertToComa(item.emp_custody,comaMoney)}}</td>
                                <td class="text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{$convertToComa(item.paid_in_advanced,comaMoney)}}</td>
                                <td class="text-center">{{$convertToComa(item.credit_note,comaMoney)}}</td>
                                <td class="text-center">{{$convertToComa(item.purchase,comaMoney)}}</td>
                                <td class="text-center">{{$convertToComa(item.outcome,comaMoney)}}</td>
                                <td class="text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{$convertToComa(item.vendor_payments,comaMoney)}}</td>
                                <td class="text-center" style="direction:ltr;">{{$convertToComa(item.cash,comaMoney)}}</td>
                                <td class="text-center" style="direction:ltr;">{{$convertToComa(item.others,comaMoney)}}</td>
                                <td class="text-center" style="direction:ltr;" v-html="$convertToComa(parseFloat(item.balance),comaMoney)"></td>
                                <td @click="ViewItem(item)" style="cursor: pointer;margin-left: 5px;width:80px;background:black !important;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;color:#fff !important; padding:5px;text-align:center;">
                                    <div>{{lang.details}}</div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="backBlack text-center">{{lang.total}}</th>
                                <th class="backBlack text-center">{{totals.inTotal}} </th>
                                <th class="backBlack text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{totals.salaries}} </th>
                                <th class="backBlack text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{totals.emp_custody}} </th>
                                <th class="backBlack text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{totals.paid_in_advanced}} </th>
                                <th class="backBlack text-center">{{totals.credit_note}} </th>
                                <th class="backBlack text-center">{{totals.purchase}} </th>
                                <th class="backBlack text-center">{{totals.outTotal}} </th>
                                <th class="backBlack text-center" v-if="$store.state.licenseType.activemenu != 'fatora'">{{totals.vendor_payments}} </th>
                                <th class="backBlack text-center">{{totals.cash}} </th>
                                <th class="backBlack text-center">{{totals.others}} </th>
                                <th class="backEmpty text-center"></th>
                                <th class="backEmpty text-center"></th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="6" v-for="(item,index) in inOutState.periodPayTypes" :key="index">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center backBlack">{{ lang.payment_type }}</th>
                                        <th class="text-center backBlack">{{ lang.old_balance }}</th>
                                        <th class="text-center backBlack">{{ lang.income }}</th>
                                        <th class="text-center backBlack">{{ lang.expense }}</th>
                                        <th class="text-center backBlack" v-if="parseFloat(item.total) < 0">{{ lang.period_balance }}</th>
                                        <th class="text-center backBlack" v-else>{{ lang.period_balance }}</th>
                                        <th class="text-center backBlack">{{ lang.balance }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center backBlack">{{ item[`name_`+lang.langname] }}</td>
                                        <td class="text-center">{{ $convertToComa($RoundNums(item.old_balance),comaMoney) }}</td>
                                        <td class="text-center">{{ $convertToComa($RoundNums(item.income),comaMoney) }}</td>
                                        <td class="text-center">{{ $convertToComa($RoundNums(item.outcome),comaMoney) }}</td>
                                        <td class="text-center">{{ $convertToComa($RoundNums(item.total),comaMoney) }}</td>
                                        <td class="text-center">{{ $convertToComa($RoundNums(parseFloat(item.old_balance) + parseFloat(item.total)),comaMoney) }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
        </div>
        <vue-snotify></vue-snotify>
        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
        <v-progress-circular
            :width="3"
            color="green"
            indeterminate
        ></v-progress-circular>
        </div>


        <div class="cusModal" v-if="showSummary">
            <div class="cusModalCont">
                <div class="cusModalTitle backBlack">{{ lang.daily_summary_for_date }} {{ currentDate }}
                    <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
                    <div class="eportBtn">
                        <div  @click="collectSearch(1)"> PDF</div>
                        <div @click="collectSearch(2)"> Excel</div>
                        <div @click="collectSearch(0)">{{lang.print}}</div>
                    </div>
                </div>
                <div class="cusModalBody">
                    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;`" v-if="showSummary">
                        <v-col cols="12">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center backBlack" style="width:100px">{{ lang.date }}</th>
                                        <th class="text-center backBlack" style="width:100px">{{ lang.time }}</th>
                                        <th class="text-center backBlack">{{ lang.description }}</th>
                                        <th class="text-center backBlack" style="width:130px">{{ lang.payment_type }}</th>
                                        <!-- <th class="text-center backBlack" style="width:100px">{{ lang.amount }}</th> -->
                                        <th class="text-center backBlack" style="width:100px">{{ lang.debet }}</th>
                                        <th class="text-center backBlack" style="width:100px">{{ lang.credit }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in summary.rows" :key="index">
                                        <td class="text-center">{{ item.date }}</td>
                                        <td class="text-center">{{ item.time }}</td>
                                        <td>{{ item[`description_`+lang.langname] }}</td>
                                        <td class="text-center">{{ item[`payment_type_`+lang.langname] }}</td>
                                        <!-- <td class="text-center">{{ item.amount }}</td> -->
                                        <td class="text-center">{{ item.debet }}</td>
                                        <td class="text-center">{{ item.credit }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="3" class="backEmpty"></th>
                                        <th class="text-end backBlack">{{ lang.total }}</th>
                                        <th class="text-center backBlack">{{ $convertToComa(summary.totals.debit,comaMoney) }}</th>
                                        <th class="text-center backBlack">{{ $convertToComa(summary.totals.credit,comaMoney) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="backEmpty"></th>
                                        <th class="text-end backBlack">{{ lang.today_balance }}</th>
                                        <th colspan="2" class="text-center backBlack" v-if="summary.current_balance >= 0">{{ $convertToComa(summary.current_balance,comaMoney) }}</th>
                                        <th colspan="2" class="text-center backBlack" v-else>{{ $convertToComa(summary.current_balance,comaMoney) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="backEmpty"></th>
                                        <th class="text-end backBlack">{{ lang.previews_balance }}</th>
                                        <th colspan="2" class="text-center backBlack" v-if="summary.previews_balance >= 0">{{ $convertToComa(summary.previews_balance,comaMoney) }}</th>
                                        <th colspan="2" class="text-center backBlack" v-else>{{ $convertToComa(summary.previews_balance,comaMoney) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="backEmpty"></th>
                                        <th class="text-end backBlack">{{ lang.total_balance }}</th>
                                        <th colspan="2" class="text-center backBlack" v-if="total_balance >= 0">{{ $convertToComa(total_balance,comaMoney) }}</th>
                                        <th colspan="2" class="text-center backBlack" v-else>{{ $convertToComa(total_balance,comaMoney) }}</th>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" md="6" v-for="(item,index) in summary.periodPayTypes" :key="index">
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th class="text-center backBlack">{{ lang.payment_type }}</th>
                                                <th class="text-center backBlack">{{ lang.old_balance }}</th>
                                                <th class="text-center backBlack">{{ lang.income }}</th>
                                                <th class="text-center backBlack">{{ lang.expense }}</th>
                                                <th class="text-center backBlack" v-if="parseFloat(item.total) < 0">{{ lang.period_balance }}</th>
                                                <th class="text-center backBlack" v-else>{{ lang.period_balance }}</th>
                                                <th class="text-center backBlack">{{ lang.balance }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center backBlack">{{ item[`name_`+lang.langname] }}</td>
                                                <td class="text-center">{{ $convertToComa($RoundNums(item.old_balance),comaMoney) }}</td>
                                                <td class="text-center">{{ $convertToComa($RoundNums(item.income),comaMoney) }}</td>
                                                <td class="text-center">{{ $convertToComa($RoundNums(item.outcome),comaMoney) }}</td>
                                                <td class="text-center">{{ $convertToComa($RoundNums(item.total),comaMoney) }}</td>
                                                <td class="text-center">{{ $convertToComa($RoundNums(parseFloat(item.old_balance) + parseFloat(item.total)),comaMoney) }}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import ExportsPrint from '@/components/exports-print.vue'
import {SnotifyPosition} from 'vue-snotify';
export default{
    name: 'InOutReport',
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer,ExportsPrint},
    data() {
        return {
            showSummary: false,
            currentDate: '',
            items: {},
            // sdate: '',
            // edate: '',
            inoutRows: [],
            periodPayTypes: [],
            search:[],
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            summary: {
                rows: [],
                previews_balance: 0,
                current_balance: 0,
                totals: {
                    credit: 0,
                    debit: 0
                },
            }
        }
    },
    computed:{ 
        ...mapGetters({
            inOutState: `inOutReport/inOutState`
        }),
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        total_balance: function(){
            let t= 0;
            t = parseFloat(this.summary.previews_balance) + parseFloat(this.summary.current_balance);
            return this.$RoundNums(t,2);
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        report: function () {
            return this.inOutState.report
        },
        comaMoney: function(){
            return this.$store.state.licenseType.moneyComma;
        },
        totals: function(){
            let t = {
                inTotal: 0,
                salaries: 0,
                credit_note: 0,
                emp_custody: 0,
                paid_in_advanced: 0,
                purchase: 0,
                outTotal: 0,
                cash: 0,
                others: 0,
                vendor_payments: 0
            }
            for(const item in this.report){
                t.inTotal = parseFloat(t.inTotal) + parseFloat(this.report[item].income);
                t.salaries = parseFloat(t.salaries) + parseFloat(this.report[item].salaries);
                t.emp_custody = parseFloat(t.emp_custody) + parseFloat(this.report[item].emp_custody);
                t.paid_in_advanced = parseFloat(t.paid_in_advanced) + parseFloat(this.report[item].paid_in_advanced);
                t.purchase = parseFloat(t.purchase) + parseFloat(this.report[item].purchase);
                t.outTotal = parseFloat(t.outTotal) + parseFloat(this.report[item].outcome);
                t.vendor_payments = parseFloat(t.vendor_payments) + parseFloat(this.report[item].vendor_payments);
                t.cash = parseFloat(t.cash) + parseFloat(this.report[item].cash);
                t.others = parseFloat(t.others) + parseFloat(this.report[item].others);
                t.credit_note = parseFloat(t.credit_note) + parseFloat(this.report[item].credit_note);
            }
            return {
                inTotal: this.$convertToComa(this.$RoundNums(t.inTotal),this.comaMoney),
                credit_note: this.$convertToComa(this.$RoundNums(t.credit_note),this.comaMoney),
                salaries: this.$convertToComa(this.$RoundNums(t.salaries),this.comaMoney),
                emp_custody: this.$convertToComa(this.$RoundNums(t.emp_custody),this.comaMoney),
                purchase: this.$convertToComa(this.$RoundNums(t.purchase),this.comaMoney),
                paid_in_advanced: this.$convertToComa(this.$RoundNums(t.paid_in_advanced),this.comaMoney),
                outTotal: this.$convertToComa(this.$RoundNums(t.outTotal),this.comaMoney),
                vendor_payments: this.$convertToComa(this.$RoundNums(t.vendor_payments),this.comaMoney),
                cash: this.$convertToComa(this.$RoundNums(t.cash),this.comaMoney),
                others: this.$convertToComa(this.$RoundNums(t.others),this.comaMoney),
            };
        },
        lastBalance: function(){
            return 0;
            // return this.$convertToComa(this.$RoundNums(this.inOutState.lastBalance),this.comaMoney)
        },
        headers: function() {
            return [
                { text: this.lang.date },
                { text: this.lang.income },
                { text: this.lang.purchase },
                { text: this.lang.expnese },
                { text: this.lang.cash },
                { text: this.lang.other },
                { text: this.lang.balance },
                { text: this.lang.balance },
            ]
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
                if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                    t.push({text: start_year + i, value: start_year+i})
                }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
    },
    methods:{
        collectSearch(id){
           this.search= {
            module: 'dailyReportDetails',
                elements: {
                    date: this.currentDate
                },
            },
            this.printMe(id);
        },
        closeTable(){
            this.showSummary = false;
            this.summary = {
                rows: [],
                previews_balance: 0,
                current_balance: 0,
                totals: {
                    credit: 0,
                    debit: 0
                },
            }
        },
        printMe(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type',"startExport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[search]',JSON.stringify(this.search));
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post,
            ).then((response) =>{
                const res = response.data;
                if(id == 1){
                    window.open(this.origin + '../../../api/export-print.php?export=' + res.results.data.newID,'_blank')
                }
                else if(id == 2)
                {
                    window.open(this.origin + '../../../api/export-print.php?excel&export=' + res.results.data.newID,'_blank')
                }else{
                    let pdfFrame = document.body.appendChild(document.createElement('iframe'));
                    pdfFrame.style.display = 'none';
                    pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
                    pdfFrame.src = this.origin + '../../../api/export-print.php?export=' + res.results.data.newID;
                    // window.print()
                }
            });},
        async ViewItem(item){
            this.showSummary = true;
            this.currentDate = item.date;
            const post = new FormData();
            post.append('type','getDailySammary'); 
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[date]',item.date)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            
            if(response && response.data){
                this.summary = response.data.results.data;
                console.log(summary);
            }else{
                // console.log(response)
            }
        },
        
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        preparedata(){
            let exdata = {
                list: 'dailyReport',
                title: this.lang.daily_report,
                data: [],
            }
            
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.edate;
            
            this.$refs.exportbar.exporttitle = this.lang.daily_report;
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        ...mapActions({
            getInOutRep: `inOutReport/getInOutRep`
        }),
        getReport() {
            const ds = new Date(this.sdate);
            const de = new Date(this.edate);
            if(ds.getTime() > de.getTime()){
                let message = "يجب ان يكون تاريخ البدء اقل من او يساوي تاريخ الانتهاء";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            this.showSummary = false
            this.getInOutRep({sdate:this.sdate,edate:this.edate,auth:this.$cookies.get('SAMCCCC-Cookies')})
        },
        async exportE(e){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','incomereport');
            // // console.log(this.report)
            let i = 0;
            for(const repo in this.report){
                
                for(const rep in this.report[repo]){
                    post.append('data['+i+']['+rep+']',this.report[repo][rep]);
                }
                i++;
            }
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            post.append('startbalance',this.lastBalance);
            post.append('auth',this.$cookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/excel.php',post
            )

            if(response && response.data){
                window.open("/api/"+response.data.url)
            }
        },
        // collectSearch(){
        //     return {
        //         module: 'inoutreport',
        //         elements: {
        //             sdate: this.sdate,
        //             edate: this.edate
        //         }
        //     }
        // }
    },
    created(){
        this.getStartDate();
        this.getInOutRep({sdate:this.sdate,edate:this.edate,auth:this.$cookies.get('SAMCCCC-Cookies')})
    }
}
</script>
<style>
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:80px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
}
</style>
